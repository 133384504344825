module.exports = [{
      plugin: require('C:/Users/riley/Documents/Programming unsynced/gatsby-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/riley/Documents/Programming unsynced/gatsby-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":75,"linkImagesToOriginal":false},
    },{
      plugin: require('C:/Users/riley/Documents/Programming unsynced/gatsby-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
